//tech stack page
import { useState } from "react";
import FilterButton from "./filterButton";
import BallList from "./ballList";
import "./filterButton.css";

const Techstack = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  return (
    <section className="page-2">
      <div className="tech-container">
        {/* <h1 className="tool-title">my Tools</h1> */}

        <div className="button-container">
          <div className="button-group-tech">
            <FilterButton
              category="Data"
              handleCategoryChange={handleCategoryChange}
            />
            <FilterButton
              category="Design"
              handleCategoryChange={handleCategoryChange}
            />
            <FilterButton
              category="Develop"
              handleCategoryChange={handleCategoryChange}
            />
          </div>
          {/* <FilterButton
            className="all-button"
            category="All"
            handleCategoryChange={handleCategoryChange}
          /> */}
        </div>
        <BallList category={selectedCategory} />
      </div>
    </section>
  );
};

export default Techstack;
