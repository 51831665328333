import "../projects.css";
import Timeline from "../timeline/StudentHubTimeline";
function StudentHub() {
  return (
    <div className="project-container">
      {/* <h1>Juz Delights</h1> */}
      {/* <img src="/assets/projects/juz-delights.png" alt="Delights Project" /> */}

      <div className="banner">
        <img
          src="/assets/projects/StudentHubRedesign.png"
          alt="Juz Delights Projectimage"
          className="banner-image"
        />
      </div>

      {/* <div className="banner">
        <h1>Juz Delights</h1>
      </div> */}

      <div className="content-section">
        <h1 style={{ textAlign: "start" }}>Student Hub Redesign</h1>
        <br />
        {/* <h2>Introduction</h2> */}
        <div className="intro">
          <div class="col" style={{ marginRight: "15px" }}>
            <h2>Overview</h2>
            <h4 style={{ maxWidth: "90%", lineHeight: "1.5" }}>
              The Student Hub is a tool that Northeastern students use to access
              all information including meal plans, dorming, class registration,
              tuition, and more. My aim through this project is to improve
              resource organization and overall website navigation.
            </h4>

            <div className="badge-container">
              <span className="badge">UI/UX</span>
              <span className="badge">Low-Code</span>
              <span className="badge">Prototyping</span>
              <span className="badge">User Research</span>
              <span className="badge">Figma</span>
            </div>
          </div>
          <div class="col">
            <h2>Quick Summary</h2>
            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • Student hub's most visited feature is the resources page which
              contains all of the websites students will need to continue as a
              student at Northeastern.
            </h4>
            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • The current student hub website does not have any branding or
              theme related to our school.
            </h4>
            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • We conducted
              <span style={{ fontWeight: "bold", color: "red" }}>
                {" "}
                user interviews
              </span>{" "}
              to get an understanding of what user frustrations are and what are
              the key priorities for the redesign.
            </h4>

            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • I iterated on a Figma
              <span style={{ fontWeight: "bold", color: "green" }}>
                {" "}
                prototype
              </span>{" "}
              , ensuring the website was fit to the schools branding, easy to
              navigate and organized.
            </h4>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      {/* <div
        class="coming-soon-banner"
        style={{
          border: "2px solid black",
          backgroundColor: "white",
          color: "black",
          padding: "20px",
          textAlign: "center",
          fontSize: "24px",
          fontWeight: "bold",
          margin: "50px auto",
          width: "100%",
        }}
      >
        Coming Soon
      </div> */}
      {/* <div className="content-section">
        <Timeline />
        <p></p>
      </div> */}

      <div className="content-section">
        {/* <h2>Details</h2> */}
        <img
          src="/assets/projects/redesign3.png"
          alt="Website redesign 3 phones"
          style={{ maxWidth: "50%" }}
        />
        <img
          src="/assets/projects/redesign2.png"
          alt="Website redesign 2 phones"
          style={{ maxWidth: "50%" }}
        />
        <p></p>
      </div>
      {/*

      <div className="content-section">
        <h2>Conclusion</h2>
        <p></p>
      </div> */}
    </div>
  );
}
export default StudentHub;
