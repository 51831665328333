import React, {useState} from 'react';
import './ball.css';
import "../vendors/bootstrap/css/bootstrap.css";
import {Tooltip} from "react-tooltip";

const Ball = ({ technology, image }) => {
    const tooltipId = `tooltip-${technology}`;
    const [showTooltip, setShowTooltip] = useState(false);
    return (
        // <div className="stage"
        //      onMouseEnter={() => setShowTooltip(true)}
        //      onMouseLeave={() => setShowTooltip(false)}
        // >
        <div className="stage">
            <div
                className="ball"
            >
                <span className="ball-shadow"></span>
                <img  src={image} alt={technology} className="ball-img" />
                {showTooltip && (
                    <div className="tooltip">
                        <span className="tooltip-text">{technology}</span>
                    </div>
                )}
            </div>

        </div>

    );
};

export default Ball;