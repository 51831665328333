import logo from "./logo.svg";
import "./App.css";
import { gsap } from "gsap";
import "./bootstrap/css/styles.css";
import Main from "./main";
import { Routes, Route, Navigate, useNavigate } from "react-router";
import Navbar from "./Components/Nav/Navbar";
import { HashRouter } from "react-router-dom";
import { useState } from "react";

import React, { useEffect } from "react";

import ProjectPage from "./Components/projects/projectPage";
function App() {
  const [darkTheme, setDarkTheme] = useState(false);

  useEffect(() => {
    const flashlight = document.getElementById("flashlight");
    const flashlightRadius = 500;
    const flashlightOffset = flashlightRadius / 2;

    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      // Position the flashlight based on the mouse's global position
      flashlight.style.left = `${clientX - flashlightOffset}px`;
      flashlight.style.top = `${clientY - flashlightOffset}px`;
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const toggleTheme = () => {
    setDarkTheme(!darkTheme);
  };
  return (
    <HashRouter>
      <div id="main">
        <div className="flashlight" id="flashlight"></div>

        <svg id="blur-filter" style={{ display: "none" }}>
          <defs>
            <filter id="flashlight-filter">
              <feGaussianBlur in="SourceGraphic" stdDeviation="15" />
            </filter>
          </defs>
        </svg>
        <div className={darkTheme ? "dark-theme" : ""}>
          <Navbar />
          <Routes>
            <Route path="/" element={<Main />} />
            {/*<Route path="/experience" element={<Experience />} />*/}
            {/* <Route path="/experience" element={<Experience2 />} /> */}
            <Route path="/project/:id" element={<ProjectPage />} />
            {/* <Route path="/projects" element={<ProjectPage />} /> */}
            {/* <Route path="/experience/details/:id" element={<Details />} /> */}
            {/* <Route path="/experience/details" element={<Details />} /> */}
          </Routes>
        </div>
      </div>
    </HashRouter>
  );
}

export default App;
