import React from "react";
import { useNavigate } from "react-router-dom";

const ProjectCard = ({ project }) => {
  const navigate = useNavigate();

  const navigateToProject = (id) => {
    navigate(`/project/${id}`);
  };

  return (
    <div
      className="card project-card"
      onClick={() => navigateToProject(project.id)}
    >
      <div
        className="project-image"
        style={{ backgroundImage: `url(${project.image})` }}
      >
        <div className="project-overlay">
          <h3>{project.title}</h3>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
