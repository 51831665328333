import "../projects.css";
import Timeline from "../timeline/juzDelightTimeline";
function MovieMates() {
  return (
    <div className="project-container">
      {/* <h1>Juz Delights</h1> */}
      {/* <img src="/assets/projects/juz-delights.png" alt="Delights Project" /> */}

      <div className="banner">
        <img
          src="/assets/projects/juz-delights.png"
          alt="Juz Delights Projectimage"
          className="banner-image"
        />
      </div>

      {/* <div className="banner">
        <h1>Juz Delights</h1>
      </div> */}

      <div className="content-section">
        <h1 style={{ textAlign: "start", fontSize: "50px" }}>Juz Delights</h1>
        <br />
        {/* <h2>Introduction</h2> */}
        <div className="intro">
          <div class="col" style={{ marginRight: "15px" }}>
            <h2>Overview</h2>
            <h4 style={{ maxWidth: "90%", lineHeight: "1.5" }}>
              Juz delights is a small business specializing in nutritious and
              delicious desserts and meals. The target audience includes
              health-conscious individuals looking for convenient access to
              high-quality, wholesome food options.
            </h4>

            <div className="badge-container">
              <span className="badge">UI/UX</span>
              <span className="badge">Low-Code</span>
              <span className="badge">Prototyping</span>
              <span className="badge">User Research</span>
              <span className="badge">Figma</span>
            </div>
          </div>
          <div class="col">
            <h2>Quick Summary</h2>
            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • Juz Delights originally focused on B2B sales and sought to
              transition to a B2C model to reach a broader consumer base. My
              team was tasked with creating a website to facilitate this shift
              and create an online presence.
            </h4>
            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • We conducted comprehensive competitor analysis to understand
              industry standards and customer needs. Based on these insights, we
              designed low to high-fidelity{" "}
              <span style={{ fontWeight: "bold", color: "green" }}>
                prototypes
              </span>{" "}
              using Figma, ensuring the website's UI was both visually appealing
              and user-friendly.
            </h4>

            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • A key requirement was that the website needed to be{" "}
              <span style={{ fontWeight: "bold", color: "#233797" }}>
                easy to update and maintain
              </span>{" "}
              , given the dynamic nature of a small business. We achieved this
              by employing a website builder that allowed for straightforward
              modifications and content management
            </h4>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div
        class="coming-soon-banner"
        style={{
          border: "2px solid black",
          backgroundColor: "white",
          color: "black",
          padding: "20px",
          textAlign: "center",
          fontSize: "24px",
          fontWeight: "bold",
          margin: "50px auto",
          width: "100%",
        }}
      >
        Coming Soon
      </div>
      {/* <div className="content-section">
        <Timeline />
        <p></p>
      </div>

      <div className="content-section">
        <h2>Details</h2>
        <p></p>
      </div>

      <div className="content-section">
        <h2>Conclusion</h2>
        <p></p>
      </div> */}
    </div>
  );
}
export default MovieMates;
