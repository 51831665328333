import React, { useEffect, useRef } from "react";
import "./about.css";
import "../boba.css";
import Techstack from "../../techstack/Techstack";
import CurrentExperience from "./CurrentExperience";

const FadeInSection = ({ children }) => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          console.log("Element is in view:", entry.target); // Debugging log
          entry.target.classList.add("fade-in");
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div ref={sectionRef} className="item">
      {children}
    </div>
  );
};
const About = () => {
  return (
    <section id="about" className="page-2">
      <div className="container about-page-container">
        <FadeInSection>
          <h1 className="">About Me</h1>
        </FadeInSection>
        <div className="row about-main-container">
          <div className="col-12 col-md-6 about-left">
            <FadeInSection>
              <p className="intro-text">
                Thanks for visiting! I'm a third year computer science student
                at Northeastern University with a minor in Interaction Design.
              </p>
            </FadeInSection>

            <FadeInSection>
              <p className="intro-text">
                My interests lie in data, human-computer interaction, and full
                stack development.
              </p>
            </FadeInSection>

            <FadeInSection>
              <CurrentExperience />
            </FadeInSection>
          </div>

          <div className="col-12 col-md-6 techstack-section">
            <FadeInSection>
              <Techstack />
            </FadeInSection>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
