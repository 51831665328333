import "./pages.css";
import "./vendors/bootstrap/css/bootstrap.css";
import "./vendors/fontawesome/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { LinkedinLogo, GithubLogo } from "@phosphor-icons/react";

import About from "./Components/about/about";
import Techstack from "./techstack/Techstack";
import BobaFilling from "./Components/bobaFilling";
import ProjectSection from "./Components/projects/projectSection";
import ExperiencePage from "./Components/about/CurrentExperience";
import Title from "./Components/intro/title";
import ExperienceMain from "./Components/experience/experience3";
import Popup from "./Components/Popup";
import Footer from "./Components/Footer/footer";

const Page1 = () => {
  return (
    <section id="home" className="page-1">
      <Title />
    </section>
  );
};

function Main() {
  const [showModal, setShowModal] = useState(true);
  const closeModal = () => {
    setShowModal(false);
  };
  const handleScrollTo = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="main-container">
      <Page1 />
      <About />
      <ExperienceMain />
      <ProjectSection />
      {/* <Footer /> */}

      <Popup show={showModal} onClose={closeModal} />
    </div>
  );
}

{
  /* <Techstack/> */
}
{
  /* <ExperiencePage /> */
}
export default Main;
