import React, { useState } from "react";
import "./projectPage.css";
import ProjectFilter from "../projects/projectFilter";
import ProjectCard from "../projects/projectCard";

// import "./bootstrap/css/styles.css";
const projects = [
  {
    id: "JuzDelights",
    title: "Juz Delights",
    topic: "Design",
    image: "/assets/projects/juz-delights2.png",
  },
  {
    id: "Collide",
    title: "Collide",
    topic: "Code",
    image: "/assets/projects/collide2.png",
  },
  // {
  //   id: 3,
  //   title: "Movie Mates",
  //   topic: "Code",
  //   image: "/assets/projects/movie_mates.png",
  // },
  // {
  //   id: 4,
  //   title: "Breaktime",
  //   topic: "Code",
  //   image: "/assets/projects/breaktimeSquare.png",
  // },
  {
    id: "TsengLab",
    title: "Tseng Lab Database",
    topic: "Code",
    image: "/assets/projects/tseng.png",
  },
  {
    id: "StudentHub",
    title: "Student Hub Redesign",
    topic: "Design",
    image: "/assets/projects/StudentHubRedesign.png",
  },
  {
    id: "NeighborhoodNav",
    title: "Neighborhood Navigator",
    topic: "Design",
    image: "/assets/projects/Neighborhood_nav2.png",
  },
  // Add more projects
];

const topics = ["All", "Data", "Design", "Code"]; // Define the topics

function ProjectSection() {
  const [selectedTopic, setSelectedTopic] = useState("All");
  const categories = [...new Set(projects.map((p) => p.topic))];
  const filteredProjects =
    selectedTopic === "All"
      ? projects
      : projects.filter((project) => project.topic === selectedTopic);

  return (
    <section id="projects" className="projects-page">
      <div className="container">
        <div className="row project-main-container">
          <h1 className="col-12 project-title">Projects</h1>

          <div className="projects-container col-12">
            {categories.map((category) => {
              const categoryProjects = filteredProjects.filter(
                (project) => project.topic === category
              );
              return (
                <div key={category} className="project-category-section">
                  {/* Sticky Title */}
                  <span className="category-title card-title text-dark sticky-title">
                    {category}
                  </span>
                  <hr className="section-divider" />

                  {/* Scrollable project grid */}
                  <div className="project-grid scrollable-projects">
                    {categoryProjects.map((project) => (
                      <div key={project.id} className="project-card-wrapper">
                        <ProjectCard project={project} />
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectSection;
