import React, { useState, useEffect } from "react";
import "./Navbar.css";
import ThemeToggleButton from "../themeToggle/themeToggle";
import { useLocation, useNavigate } from "react-router-dom";
function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [scrollDirection, setScrollDirection] = useState("up");
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const [darkTheme, setDarkTheme] = useState(false);

  useEffect(() => {
    const savedTheme = localStorage.getItem("darkTheme") === "true";
    setDarkTheme(savedTheme);
    if (savedTheme) {
      document.body.classList.add("dark-theme");
    }
  }, []);

  const toggleTheme = () => {
    setDarkTheme((prevTheme) => {
      const newTheme = !prevTheme;
      if (newTheme) {
        document.body.classList.add("dark-theme");
      } else {
        document.body.classList.remove("dark-theme");
      }
      localStorage.setItem("darkTheme", newTheme);
      return newTheme;
    });
  };

  const handleScrollTo = (id) => {
    console.log(window.location.pathname);
    if (location.pathname !== "/") {
      // Navigate to the root path
      navigate("/", { replace: true }); // Ensure replace is true to prevent adding history entry
      setTimeout(() => {
        // After navigation, scroll to the section
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 300); // Adjust delay if needed
    } else {
      // Scroll to the section directly if already on the home page
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  return (
    <header className="navbar">
      <ul>
        {/* <li>
          <ThemeToggleButton toggleTheme={toggleTheme} />
        </li> */}
        <li>
          <button onClick={() => handleScrollTo("about")}>About</button>
        </li>
        <li>
          <button onClick={() => handleScrollTo("experience-container")}>
            Experience
          </button>
        </li>
        <li>
          <button onClick={() => handleScrollTo("projects")}>Projects</button>
        </li>
      </ul>
    </header>
  );
}

export default Navbar;
