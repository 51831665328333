import React, { useEffect, useRef } from "react";
import "./title.css";
import { LinkedinLogo, GithubLogo } from "@phosphor-icons/react";
import rachelImg from "../intro/rachel-img.png";

const Title = () => {
  return (
    <div className="row title-container">
      <div className="home col-12 col-md-6">
        <h1 className="title">Rachel Ma</h1>
        <h2 className="below"> data, designer, developer </h2>
        <div class="icons">
          <a
            href="https://www.linkedin.com/in/rachel-ma2"
            classname="iconsImg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedinLogo size={40} color="#1A1A1A" />
          </a>
          <a
            href="https://github.com/Ma-rachl"
            classname="iconsImg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GithubLogo size={40} color="#1A1A1A" />
          </a>
        </div>
      </div>

      <div className="about-right col-12 col-md-6 d-none d-md-block">
        <img src={rachelImg} alt="Rachel" className="img-fluid" />
      </div>
    </div>
  );
};

export default Title;
