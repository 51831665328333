import "../projects.css";
import Timeline from "../timeline/juzDelightTimeline";
import { ArrowRightUp } from "@icon-park/react";
function Collide() {
  return (
    <div className="project-container">
      <div className="banner">
        <img
          src="/assets/projects/collide3.png"
          alt="Collide Projectimage"
          className="banner-image"
        />
      </div>

      <div className="content-section">
        <a href="https://interventions.design/" className="link-style">
          <h1 className="project-title">
            Collide <ArrowRightUp theme="outline" size="24" fill="#333" />
          </h1>
        </a>
        <br />
        {/* <h2>Introduction</h2> */}
        <div className="intro">
          <div class="col" style={{ marginRight: "15px" }}>
            <h2>Overview</h2>

            <h4 style={{ maxWidth: "90%", lineHeight: "1.5" }}>
              Scout is a student-led design agency at Northeastern. Each year,
              Scout holds a design conference consisting of panelists and
              workshops. This year's theme was Collide.
            </h4>

            <div className="badge-container">
              <span className="badge">Animation</span>
              <span className="badge">Code</span>
            </div>
          </div>
          <div class="col">
            <h2>Quick Summary</h2>
            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • The theme for Scout's 7th annual design conference was
              Interventions: Collide. Within a tight 1 month timeframe, Scout's
              conference team
            </h4>
            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • The conference tech team, consisting of 4 developers, had under
              two months to develop a comprehensive website that housed all
              conference-related information
            </h4>

            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • The website needed to be{" "}
              <span style={{ fontWeight: "bold", color: "#233797" }}>
                interactive
              </span>
              ,{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>animated</span>
              , and{" "}
              <span style={{ fontWeight: "bold", color: "orange" }}>
                user-friendly
              </span>{" "}
              to ensure an engaging and seamless experience for all attendees.
            </h4>
            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • I worked on multiple components including the homepage animation
              :)
            </h4>
          </div>
        </div>
      </div>
      <br />

      {/* <img
        src="/assets/projects/collide-animation.gif"
        alt="Collide Projectimage"
        className="banner-image"
      /> */}

      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="/assets/projects/collide-animation.gif"
          alt="Collide Projectimage"
          className="banner-image"
          style={{
            maxWidth: "70%",
          }}
        />
      </div>
      <br />
      <br />
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Check out our scroll based animation
        <span style={{ marginLeft: "10px" }}>
          <a href="https://interventions.design/" className="link-style">
            https://interventions.design/
          </a>
        </span>
      </p>

      {/* <div
        class="coming-soon-banner"
        style={{
          border: "2px solid black",
          backgroundColor: "white",
          color: "black",
          padding: "20px",
          textAlign: "center",
          fontSize: "24px",
          fontWeight: "bold",
          margin: "50px auto",
          width: "100%",
        }}
      >
        Coming Soon
      </div> */}

      <br />
      <br />
      <br />
      {/* <div className="content-section">
        <Timeline />
        <p></p>
      </div>

      <div className="content-section">
        <h2>Details</h2>
        <p></p>
      </div>

      <div className="content-section">
        <h2>Conclusion</h2>
        <p></p>
      </div> */}
    </div>
  );
}
export default Collide;
