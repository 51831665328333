import "./projects.css";
import Timeline from "./timeline/juzDelightTimeline";
function Collide() {
  return (
    <div className="project-container">
      {/* <h1>Juz Delights</h1> */}
      {/* <img src="/assets/projects/juz-delights.png" alt="Delights Project" /> */}

      <div className="banner">
        <img
          src="/assets/projects/tsenglab2.png"
          alt="tsenglab image"
          className="banner-image"
        />
      </div>

      {/* <div className="banner">
        <h1>Juz Delights</h1>
      </div> */}

      <div className="content-section">
        <h1 className="project-title">Tseng Lab Database</h1>

        <br />
        {/* <h2>Introduction</h2> */}
        <div className="intro">
          <div class="col" style={{ marginRight: "15px" }}>
            <h2>Overview</h2>

            <h4 style={{ maxWidth: "90%", lineHeight: "1.5" }}>
              In this lab, I worked independently to create an database query
              system with an intuitive UI
            </h4>

            <div className="badge-container">
              <span className="badge">Design</span>
              <span className="badge">Data Visualizations</span>
              <span className="badge">Data</span>
              <span className="badge">Code</span>
            </div>
          </div>
          <div class="col">
            <h2>Quick Summary</h2>

            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • Went through several iterations using Excel, SQL, Tableau and
              ultimately choosing Python.
            </h4>

            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • I Collaborated closely with stakeholders of non-technical
              backgrounds, translating their needs into actionable data
              visualizations through numerous iterations and feedback loops.
            </h4>

            <h4
              style={{ maxWidth: "95%", lineHeight: "1.5", fontSize: "20px" }}
            >
              • Developed{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                complex visualizations{" "}
              </span>{" "}
              for gene and single-cell RNA sequencing data, integrating
              multi-dimensional analysis to allow for easy access to patterns
              and insights.
            </h4>
          </div>
        </div>
      </div>
      <br />

      {/* 
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="/assets/projects/collide-animation.gif"
          alt="Collide Projectimage"
          className="banner-image"
          style={{
            maxWidth: "70%",
          }}
        />
      </div> */}
      <div
        class="coming-soon-banner"
        style={{
          border: "2px solid black",
          backgroundColor: "white",
          color: "black",
          padding: "20px",
          textAlign: "center",
          fontSize: "24px",
          fontWeight: "bold",
          margin: "50px auto",
          width: "100%",
        }}
      >
        This is a private project.
        <span
          style={{
            marginLeft: "10px",
            marginRight: "10px",
            textDecorationLine: "underline",
          }}
        >
          <a href="mailto:ma.ra@northeastern.edu" className="link-style">
            Email
          </a>
        </span>
        with any questions.
      </div>

      <br />
      <br />

      <br />
      <br />
      <br />
    </div>
  );
}
export default Collide;
