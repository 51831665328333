import { useRef, useEffect, useState } from "react";
import "../about/about.css";
import "../../vendors/bootstrap/css/bootstrap.css";

import "./experience.css";
import "../experience/ExperienceCard";
import ExperienceCard from "../experience/ExperienceCard";

function ExperienceMain() {
  const experiences = [
    {
      title: "re:Bloom",
      location: "Remote",
      role: "Designer & Developer Consultant",
      time: "May 2024 - August 2024",
      summary: "Re:bloom | May 2024 - August 2024",
      highlight: ["Figma"],
      link: "https://www.re-bloom.org/",
      details:
        "💻️ product design, stakeholder managment\n 👩🏻‍💻 Figma \n   • Product design and development of a comprehensive eCommerce website. \n  • Utilized Figma to create high-fidelity prototypes, incorporating user feedback and iterative testing to refine the design. \n • Conducted in-depth competitor analysis to identify industry trends and help the buisness transition from B2B to B2C \n • Collaborated closely with the stakeholder to understand business goals, customer needs, and technical constraints. \n  ",
    },
    {
      title: "Jefferies",
      location: "New York, NY",
      role: "Summer Analyst",
      time: "June 2024 - August 2024",
      summary: "June 2024 - August 2024",
      details: "Corporate Technology Intern",

      highlight: [""],
    },
    {
      title: "Priceline",
      location: "New York, NY",
      role: "ML Developer Co-op",
      time: "Jaunary 2024 - June 2024",
      summary: "Priceline | January 2024 - June 2024 ",
      highlight: ["Python", "SQL", "Vertex AI"],
      link: "https://www.priceline.com/",
      details:
        "💻️ worked on various genai use cases\n   • Conducted prompt engineering on key customer facing hotel data. Worked with Text bison, Gemini Pro, GPT 4o and more \n • Developed the workflows for large scale content development using Vertex AI \n • Collaborated with product managers and software engineers to figure out how to optimize data that is shown on Priceline \n • Won first place in annual Hackathon!!",
    },

    {
      location: "Boston, MA",
      title: "Tseng Lab",
      role: "Data Intern",
      time: "September 2023 - August 2024",
      summary: "Tseng Lab | September 2023 - July 2024",
      highlight: ["Python", "SQL", "Streamlit"],
      details:
        "💻️ data, product design, stakeholder managment\n 👩🏻‍💻 SQL, Tableau, Excel, Python \n   • Developed a database to help organize the lab's extensive gene expression datasets. ",
    },
    {
      title: "Code4Community",
      location: "Boston, MA",
      role: "Director of Recruitment & Developer",
      time: "September 2023 - Present",
      summary: "Code4Community | September 2024 - Present",
      highlight: ["React"],
      link: "https://www.c4cneu.com/",
      details:
        "💻️ web development, client outreach, and developer recruitment\n 👩🏻‍💻 React, Notion \n   • Coordinated a team of 10+ individuals to review applications and conduct interviews, ensuring consistency to accommodate 150+ applicants \n• Developed content materials, including application questions, interview guidelines, and other administrative documents\n • Contributing to the ongoing development of a time tracking and grant management system for Breaktime, a non-profit clientdedicated to combating youth homelessness, using TypeScript, AWS Cognito, and DynamoDB \n• Conducted outreach efforts to attract and onboard 3+ new clients, expanding the organizations reach and impact",
    },
    {
      title: "Scout",
      location: "Boston, MA",
      role: "Developer",
      time: "January 2024 - Present",
      summary: "September 2024 - Present",
      highlight: ["React"],
      link: "https://www.c4cneu.com/",
      details:
        "💻️ web development,\n 👩🏻‍💻 TypeScript, Gsap, \n  •Worked on front-end for Conference website using Typescript. Used CSS and GSAP to create a scroll based animation. \n         \n      \n ",
    },

    // {
    //   title: "UI Developer ",
    //   summary: "Scout | January 2024 - Present",
    //   details:
    //     "💻️ web development\n 👩🏻‍💻 TypeScript, CSS, GSAP \n   •Worked on front-end for Conference website using Typescript. Used CSS and GSAP to create a scroll based animation.",
    // },
  ];

  const [visibleExperiences, setVisibleExperiences] = useState(experiences);
  // useEffect(() => {
  //   const scrollIndicator = document.querySelector(".scroll-indicator");

  //   const scrollToNextSection = () => {
  //     window.scrollBy({
  //       top: window.innerHeight,
  //       behavior: "smooth",
  //     });
  //   };

  //   scrollIndicator.addEventListener("click", scrollToNextSection);

  //   return () => {
  //     scrollIndicator.removeEventListener("click", scrollToNextSection);
  //   };
  // }, []);

  // useEffect(() => {
  //   const flashlight = document.getElementById("flashlight");
  //   const container = document.getElementById("experience-container");
  //   const flashlightRadius = 500;
  //   const flashlightOffset = flashlightRadius / 2;

  //   const handleMouseMove = (event) => {
  //     const { clientX, clientY } = event;
  //     const { left, top } = container.getBoundingClientRect();
  //     flashlight.style.left = `${clientX - left - flashlightOffset}px`;
  //     flashlight.style.top = `${clientY - top - flashlightOffset}px`;
  //   };

  //   window.addEventListener("mousemove", handleMouseMove);

  //   return () => {
  //     window.removeEventListener("mousemove", handleMouseMove);
  //   };
  // }, []);
  return (
    <section className="experience-page" id="experience-container">
      {/* <div className="flashlight" id="flashlight"></div>

      <svg id="blur-filter" style={{ display: "none" }}>
        <defs>
          <filter id="flashlight-filter">
            <feGaussianBlur in="SourceGraphic" stdDeviation="15" />
          </filter>
        </defs>
      </svg> */}

      <div className="container experience-page-container">
        <div className="row experience-main-container">
          <h1 className="col-12">Experience</h1>
          <div className="experience-slider col-12">
            {visibleExperiences.map((exp, index) => (
              <div key={index} className="experience-slide">
                <div className="experience-card">
                  {/* Left side: Title and Location */}
                  <div className="experience-left">
                    <a
                      href={exp.link}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {" "}
                      <span className="experience-title">{exp.title}</span>
                    </a>

                    <p className="experience-location">{exp.location}</p>
                  </div>

                  {/* Right side: Summary and Role */}
                  <div className="experience-right">
                    <span className="experience-role">{exp.role}</span>
                    <p className="experience-summary">{exp.time}</p>
                    <div className="card-text text-muted">
                      {exp.details.split("\n").map((line, index) => (
                        <p key={index} style={{ marginBottom: "1rem" }}>
                          {line}
                        </p>
                      ))}
                    </div>
                  </div>
                  <div className="card-separator"></div>
                </div>
              </div>
            ))}
            {/* <div className="scroll-indicator" style={{ alignSelf: "center" }}>
              ↓
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ExperienceMain;
