import React from "react";
import "./Popup.css";

const Popup = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Notice</h2>
        <p>The Mobile view of this site is under construction</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Popup;
