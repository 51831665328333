import React from "react";
import Ball from "./ball";
import "../vendors/bootstrap/css/bootstrap.css";
import "./ball.css";
import JavaLogo from "./assets/java.png";
import PythonLogo from "./assets/python.png";
import HtmlLogo from "./assets/html.png";
import JsLogo from "./assets/js.png";
import SqlLogo from "./assets/sql.png";
import ReactLogo from "./assets/react.png";
import GitLogo from "./assets/git.png";
import FigmaLogo from "./assets/figma.png";
import { Tooltip } from "react-tooltip";

const ballList = ({ category }) => {
  // const techStack = [
  //     { technology: 'Java', image: JavaLogo },
  //     { technology: 'Python', image: PythonLogo },
  //     { technology: 'Sql', image: SqlLogo },
  //     { technology: 'HTML/CSS', image: HtmlLogo },
  //     { technology: 'JS', image: JsLogo },
  //     { technology: 'React', image: ReactLogo },
  //     { technology: 'Figma', image: FigmaLogo },
  //     { technology: 'Git', image: GitLogo },
  //
  //
  // ];

  let techStack = [];
  if (category === "Design") {
    techStack = [
      { technology: "HTML/CSS", image: HtmlLogo },
      { technology: "JavaScript", image: JsLogo },
      { technology: "React", image: ReactLogo },
      { technology: "Figma", image: FigmaLogo },
    ];
  } else if (category === "Data") {
    techStack = [
      { technology: "Python", image: PythonLogo },
      { technology: "Sql", image: SqlLogo },
    ];
  } else if (category === "Develop") {
    techStack = [
      { technology: "Java", image: JavaLogo },
      { technology: "Python", image: PythonLogo },
      { technology: "Sql", image: SqlLogo },
      { technology: "HTML/CSS", image: HtmlLogo },
      { technology: "JavaScript", image: JsLogo },
      { technology: "React", image: ReactLogo },
      { technology: "Git", image: GitLogo },
    ];
  } else {
    techStack = [
      { technology: "Java", image: JavaLogo },
      { technology: "Python", image: PythonLogo },
      { technology: "Sql", image: SqlLogo },
      { technology: "HTML/CSS", image: HtmlLogo },
      { technology: "JavaScript", image: JsLogo },
      { technology: "React", image: ReactLogo },
      { technology: "Figma", image: FigmaLogo },
      { technology: "Git", image: GitLogo },
    ];
  }

  const filteredTechStack =
    category === "All"
      ? techStack
      : techStack.filter((tech) => tech.category === category);

  return (
    <div className="tech-stack-container">
      <div className="tech-stack">
        {techStack.map((tech, index) => (
          <div key={index} className="ball-item">
            <div className="ball">
              <img
                src={tech.image}
                alt={tech.technology}
                className="ball-img"
              />
            </div>
            <span className="ball-text">{tech.technology}</span>
          </div>
        ))}
      </div>

      {/* <div className="tech-labels">
        <ul className="tech-labels-list">
          {techStack.map((tech, index) => (
            <li className="language-text" key={index}>
              {tech.technology}
            </li>
          ))}
        </ul>
      </div> */}
    </div>
  );
};

export default ballList;
