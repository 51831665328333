import { useNavigate } from "react-router";

const CurrentExperience = () => {
  const navigate = useNavigate();

  return (
    <div className="">
      <br />
      <span className="intro-text-experience">
        {/* 👩🏻‍💻 Technology Summer Analyst @ Jefferies <br /> */}
        💡 UX Designer @ Northeastern IT services <br />
        💡 Software Designer @ Generate <br />
        💻 Tech Lead @ Scout <br />
        📋 Director of Recruitment @ Code4Community <br />
      </span>

      <div></div>
    </div>
  );
};

export default CurrentExperience;
